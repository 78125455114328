<template>
    <v-row>
        <v-col>
            <v-card class="pa-4">
                <v-card-title>{{ user.phone }}</v-card-title>
                <v-card-text>
                    <UserTabLinks />
                    <v-row class="pl-3 pb-3" />
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <td>Название</td>
                                    <td>Код</td>
                                    <td>Активен</td>
                                    <td></td>
                                    <!-- <td></td> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in userSites" :key="item.id">
                                    <td>{{ item.title }}</td>
                                    <td>{{ item.code }}</td>
                                    <td>
                                        <span :class="`true-false-label ${getBadge(item.enabled)}`">
                                            &nbsp;{{item.enabled ? 'Да' : 'Нет' }}&nbsp;
                                        </span>
                                    </td>
                                    <td align="right">
                                        <v-icon color="primary" @click="remove(item.user_site_id)">mdi-trash-can</v-icon>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
                <v-card-actions class="pa-4 d-flex justify-space-between">
                    <div>
                        <v-btn color="primary" class="mr-3" @click="create" v-if="filteredEntities.length">
                            <v-icon>mdi-plus</v-icon> Добавить
                        </v-btn>
                        <v-btn color="light" class="mr-3" @click="goBack" >Назад</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-col>

        <v-dialog
            v-model="editModal"
            width="800px"
        >
            <v-card>
                <v-card-title class="primary">
                    Бизнес
                </v-card-title>

                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col>
                                <v-select
                                    v-model="editUserSite.site_id"
                                    label="Бизнес"
                                    :items="filteredEntities"
                                    item-text="title"
                                    item-value="id"
                                    outlined
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn
                        color="primary"
                        class="mr-3"
                        @click="save"
                    >Сохранить</v-btn>
                    <v-btn
                        color="light"
                        class="ml-3"
                        @click="editModal = false"
                    >Отмена</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="editUserSite.id"
                        color="error"
                        class="ml-3"
                        @click="remove"
                    >Удалить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    import store from '../../store';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { mapState } from 'vuex';
    import UserTabLinks from '../../components/users/UserTabLinks';

    export default {
        name: 'UserEntities',

        components: {
            UserTabLinks,
        },

        data() {
            return {
                editModal: false,
                editUserSite: {}
            };
        },

        computed: {
            ...mapState('users', {
                user: state => state.entity
            }),
            ...mapState('sites', {
                sites: state => state.entities
            }),
            userSites() {
                return this.user && this.user.sites ? this.user.sites : [];
            },
            filteredEntities() {
                if(this.user && this.user.sites) {
                    const siteIds = this.user.sites.map(item => item.id);
                    return this.sites.filter(item => {
                        return siteIds.indexOf(item.id) < 0;
                    });
                }
                return this.sites;
            }
        },

        async mounted() {
            await store.dispatch('users/get', { id: this.$route.params.id });
            await store.dispatch('sites/fetch', {});
        },

        methods: {
            create () {
                this.editUserSite = {
                    user_id: this.user.id,
                    site_id: ''
                };
                this.editModal = true;
            },
            edit(item) {
                this.editUserSite = {
                    id: item.user_site_id,
                    user_id: this.user.id,
                    site_id: item.id
                };
                this.editModal = true;
            },
            async save() {
                const { id } = this.$route.params;
                try {
                    store.commit('userSites/SET_ENTITY', this.editUserSite);
                    await store.dispatch('userSites/save', { user_id: id });
                    await store.dispatch('users/get', { id });
                    this.editModal = false;
                } catch (error) {
                    alert('Ошибка сохранения');
                }
            },
            async remove(user_site_id) {
                const { id } = this.$route.params;
                try {
                    await store.dispatch('userSites/delete', { user_id: id, id: user_site_id });
                    await store.dispatch('users/get', { id });
                } catch (error) {
                    alert('Ошибка удаления');
                }
            },
            getBadge (status) {
                return status ? 'success' : 'error';
            },
            goBack() {
                const { id } = this.$route.params;
                if(id) {
                    this.$router.push(`/users/${id}/detail`);
                } else {
                    this.$router.push('/users');
                }
            },
        },
    };
</script>